
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { IUserLevel } from '@/core/data/userLevel';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import { ICustomer } from '@/core/data/customers';

export default defineComponent({
	name: 'user-details',
	components: {
		PricePlanModal
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const formRef = ref<null | HTMLFormElement>(null);
		const userLevels = ref<Array<IUserLevel>>([]);
		const childCustomers = ref<Array<ICustomer>>([]);
		const loading = ref<boolean>(false);

		const subscriptionData = ref<any>([]);

		const isEdit = route.params.id;
		const userId = ref(route.params.id);
		const formData = ref({
			firstName: '',
			lastName: '',
			username: '',
			// email: '',
			childCustomerId: undefined,
			active: false,
			level: undefined, // 1,2,3 etc...
			role: '' // Admin, Customer, User  (Only Admin can add Admin and Customer) Customer can add Customer and User
		});
		
		const { role } = store.getters.currentUser;

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const getuserDetails = () => {
			ApiService.get(`User/${selectedCustomer.value}/${userId.value}`)
				.then(({ data }) => {
					formData.value = { ...formData.value, ...data };
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const setSelectedPricePlan = item => {
			console.log('item:', item);

			subscriptionData.value.push({
				orderId: null,
				pricePlanId: item.pricePlanId,
				status: "succeeded",
				adyenSubscription: false,
				validTill: "2023-09-30T00:00:00",
				colorValidTill: true,
				pricePlanName: item.title,
				pricePlanType: item.planType,
				price: item.price
			});
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevels.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getChildCustomer = () => {
			ApiService.get(`Customer/GetChildren/${selectedCustomer.value}`)
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					childCustomers.value.splice(0, childCustomers.value.length, ...data);
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} User`, ['Users']);
			getUserLevels();
			getChildCustomer();
			if (isEdit) {
				getuserDetails();
				getSubscriptions();
			}
		});

		const rules = ref({
			firstName: [
				{
					required: true,
					message: 'First name is required',
					trigger: 'change'
				}
			],
			lastName: [
				{
					required: true,
					message: 'Last name is required',
					trigger: 'change'
				}
			],
			// email: [
			// 	{
			// 		required: true,
			// 		message: 'User email is required',
			// 		trigger: 'change'
			// 	}
			// ],
			username: [
				{
					required: true,
					message: 'User email is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;

					const data: any = formData.value;
					Object.keys(data).forEach(key => {
						if (data[key] === null || data[key] === '') {
							delete data[key];
						}
					});

					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`User/${selectedCustomer.value}/${route.params?.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post(
							`User/${selectedCustomer.value}`,
							data
						);
					}

					serviceType
						.then(({ data }) => {
							if(!userId.value)
							{
								userId.value = data.id
							}

							saveSubscriptions();

							loading.value = false;
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = { ...formData.value, ...data };
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();

									subscriptionData.value = []
								}
							});
						})
						.catch(({ response }) => {
							console.log('create User error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const getSubscriptions = () => {
			loading.value = true;
			ApiService.get(`Order/GetOrdersForUser/${userId.value}`)
				.then(({ data }) => {
					subscriptionData.value = data;
					console.log(data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				})
				.finally(() => {
					loading.value = false;
				});
		};

		const saveSubscriptions = () => {
			console.log('saveSubscriptions');

			loading.value = true;
			
			const reqData: any = { userId: userId.value, orderId: subscriptionData.value };

			ApiService.post(`Order/SaveSubscriptions/${reqData.userId}`, reqData.orderId)
				.then(res => {
					console.log(res.data);
				})
				.catch(e => {
					console.log(e);
				})
				.finally(() => {
					loading.value = false;
					getSubscriptions();
				});
		};

		const deleteSubscription = (orderId) => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, unsubscription it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					const reqData: any = { userId: userId.value, orderId: orderId };
	
					ApiService.post(`User/cancelsubscriptionorder`, reqData)
						.then(res => {
							console.log(res.data);
						})
						.catch(e => {
							console.log(e);
						})
						.finally(() => {
							loading.value = false;
							getSubscriptions();
						});

				}
			});
		};

		return {
			role,
			rules,
			isEdit,
			submit,
			userId,
			formRef,
			loading,
			formData,
			userLevels,
			childCustomers,
			subscriptionData,
			deleteSubscription,
			setSelectedPricePlan
		};
	}
});
